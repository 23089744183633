<script setup>
import { useWorkflowStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useWorkflowStatus";
import {
  onMounted,
  onUnmounted,
  defineEmits,
  defineProps,
  defineExpose,
  computed
} from "vue";
import ArticleProgressBar from "@/components/ExternalApps/SalesChannelManagementApp/components/ArticleProgressBar.vue";
import { useStore } from "@/core/services/store";

const props = defineProps({
  workflowId: {
    type: String,
    default: ""
  }
});

defineEmits(["show-dialog"]);

const {
  workflowPending,
  currentWorkflowStatus,
  isMounted,
  checkForPendingWorkingJobs,
  runWorkflow,
  timeout
} = useWorkflowStatus(props.workflowId);

const store = useStore();

const isBusy = computed(() =>
  store.getters["loadingQueue/showLoadingBar"]("workflowStatus")
);

onMounted(() => {
  isMounted.value = true;
  checkForPendingWorkingJobs();
});

onUnmounted(() => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  isMounted.value = false;
  workflowPending.value = false;
});

defineExpose({
  runWorkflow
});
</script>

<template>
  <div>
    <ArticleProgressBar
      v-show="workflowPending"
      :current-status="currentWorkflowStatus"
      :title="$t('salesChannelManagementApp.initialFile.building').toString()"
      :info="
        $t('salesChannelManagementApp.initialFile.buildingInfo').toString()
      "
    />
    <button
      v-if="!workflowPending && !isBusy"
      class="btn btn-primary mt-2"
      @click="$emit('show-dialog')"
    >
      {{ $t("salesChannelManagementApp.initialFile.generateInitialFile") }}
    </button>
  </div>
</template>

<style lang="scss" scoped></style>
