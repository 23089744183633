import ProcessManager from "@/components/Workflows/processManager";
import Project from "@/components/Settings/Projects/project";
import i18n from "@/core/plugins/vue-i18n";
import { Error, Toast } from "@/core/plugins/swal";
import { UPDATE_SELECTED_PROJECT } from "@/core/services/store/switchArea.module";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { computed, ref } from "vue";
import { loadProjects } from "@/composables/useSalesChannelManagementApp";
import { useStore } from "@/core/services/store";
import { getSubdomain } from "@/core/services/api.service";
import { useAppSave } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppSave";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";

const channels = ref([]);
const store = useStore();
const show = ref(false);
const selectedChannelId = ref(null);
const createdProject = ref({});

const LOAD_CHANNELS_WORKFLOW_ID = store.getters.loadChannelsWorkflowId;
const MIGRATION_SHOP_IMPORTER_WORKFLOW_ID = computed(
  () => store.getters.environmentVariables.shopImporterWorkflowId
);
const licenseIds = store.getters.shopImporterLicenseIds?.split(",") ?? [];
const shops = store.getters.shopImporterShopsGA?.split(",") ?? [];

export const useMigrationIntegration = () => {
  const { setProductSelectionValue } = useAppSave();
  const { setMigrationChecksConfigValue } = useMigration();
  const isLocked = computed(
    () => !!store.getters["loadingQueue/showLoadingBar"]("startMigration")
  );

  const shopImporterActive = computed(() =>
    licenseIds.includes(getSubdomain(window.location.origin))
  );

  const shopActive = computed(() =>
    shops.includes(store.getters.selectedApp.name)
  );

  const selectedChannel = computed(
    () =>
      channels.value?.find(c => c?.id === selectedChannelId.value?.channelId) ??
      {}
  );

  async function startMigration(params) {
    addEventToLoadingQueue({
      key: "startMigration"
    });

    await createProject();

    if (Object.keys(createdProject.value || {}).length > 0) {
      await migrateIntegration(params);
      await setMigrationChecksConfigValue(createdProject.value.id);

      if (params.selectionStrategy === "adoptExisting") {
        await setProductSelectionValue(createdProject.value.id);
      }
    }

    await loadProjects();
    resetValues();

    removeEventFromLoadingQueue({
      key: "startMigration"
    });
  }
  async function loadChannelsForShop() {
    if (!shopActive.value && !shopImporterActive.value) {
      return;
    }

    let optionalHeaders = {
      "x-project-id": store.getters.xentralPresetProjectId
    };

    try {
      const response = await ProcessManager.run(
        LOAD_CHANNELS_WORKFLOW_ID,
        {
          dispatchImmediately: true
        },
        optionalHeaders
      );

      channels.value = response.data.return.data.filter(
        c => c.type === store.getters.selectedApp.name
      );
    } catch (error) {
      Error(error);
    }
  }
  async function createProject() {
    if (!selectedChannel.value) {
      return Toast.fire({
        icon: "warning",
        title: i18n.t("salesChannelManagementApp.migration.noChannelSelected")
      });
    }

    const projectName =
      store.getters.selectedApp.name + " " + selectedChannel.value.name;

    await Project.store({
      name: projectName,
      preset_instance_id: store.getters.presetInstanceId,
      preset_project_id: store.getters.presetProjectId,
      preset_id: store.getters.presetId
    })
      .then(async response => {
        createdProject.value = response.data;

        await store.dispatch(UPDATE_SELECTED_PROJECT, createdProject.value);
      })
      .catch(() => {
        Toast.fire({
          icon: "error",
          title: i18n.t(
            "salesChannelManagementApp.migration.projectCreateFailure"
          )
        });
      });
  }

  async function migrateIntegration(params) {
    if (!params) return;

    params.uuid = selectedChannel.value.id;
    await ProcessManager.run(MIGRATION_SHOP_IMPORTER_WORKFLOW_ID.value, {
      dispatchImmediately: true,
      params
    })
      .then(() => {
        Toast.fire({
          icon: "success",
          title: i18n.t(
            "salesChannelManagementApp.migration.migrationSuccessful"
          )
        });
      })
      .catch(() => {
        Toast.fire({
          icon: "error",
          title: i18n.t("salesChannelManagementApp.migration.migrationFailure")
        });
      });
  }

  function resetValues() {
    createdProject.value = {};
    selectedChannelId.value = null;
    show.value = false;
  }

  return {
    MIGRATION_SHOP_IMPORTER_WORKFLOW_ID,
    isLocked,
    shopImporterActive,
    shopActive,
    show,
    store,
    channels,
    selectedChannel,
    selectedChannelId,
    createProject,
    loadChannelsForShop,
    startMigration
  };
};
