var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.selectedArea === 'wizard')?[(
        _setup.selectedIntegration.id in (_setup.migrationChecks?.value ?? []) &&
        !_setup.hasOnlyMigration &&
        _setup.hasMigration
      )?_c(_setup.FormHelper,{attrs:{"form":_setup.migrationStatusForm,"config":{
        title: _vm.$t('salesChannelManagementApp.migration.changeMigrationTitle'),
        snippetPrefix: 'salesChannelManagementApp',
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      }},on:{"change":function($event){return _setup.onChangeMigrationStatus($event)}},model:{value:(_setup.migrationStatus),callback:function ($$v) {_setup.migrationStatus=$$v},expression:"migrationStatus"}}):_vm._e(),(
        !_setup.store.getters?.areaBlacklist?.includes('wizard.baseData') ?? true
      )?_c(_setup.FormHelper,{attrs:{"form":_setup.store.getters.form('wizard.baseData'),"config":{
        title: _vm.$t('salesChannelManagementApp.livemode'),
        snippetPrefix: 'salesChannelManagementApp',
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      }},on:{"change":function($event){return _setup.formValueChange($event, 'baseData')}},model:{value:(_setup.selectedIntegration.baseData.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.baseData, "value", $$v)},expression:"selectedIntegration.baseData.value"}}):_vm._e(),_c(_setup.FormHelper,{attrs:{"form":_setup.store.getters.form('wizard.credentials'),"config":{
        title: _vm.$t('salesChannelManagementApp.credentials'),
        snippetPrefix: 'salesChannelManagementApp',
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      }},on:{"change":function($event){return _setup.formValueChange($event, 'credentials')}},model:{value:(_setup.selectedIntegration.credentials.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.credentials, "value", $$v)},expression:"selectedIntegration.credentials.value"}}),(_setup.store.getters.form('wizard.sftpData'))?_c(_setup.FormHelper,{attrs:{"config":{
        title: _vm.$t('salesChannelManagementApp.titles.sftpData'),
        snippetPrefix: 'salesChannelManagementApp',
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      },"form":_setup.store.getters.form('wizard.sftpData')},on:{"change":function($event){return _setup.formValueChange($event, 'sftpData')}},model:{value:(_setup.selectedIntegration.credentials.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.credentials, "value", $$v)},expression:"selectedIntegration.credentials.value"}}):_vm._e(),(_setup.store.getters.form('wizard.features'))?_c(_setup.FormHelper,{attrs:{"form":_setup.store.getters.form('wizard.features'),"config":{
        title: _vm.$t('salesChannelManagementApp.features'),
        snippetPrefix: 'salesChannelManagementApp',
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      }},on:{"change":event => {
          _setup.onFeaturesChanged(event);
          _setup.formValueChange(event, 'features');
        },"input":_setup.onFeaturesInput},model:{value:(_setup.selectedIntegration.features.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.features, "value", $$v)},expression:"selectedIntegration.features.value"}}):_vm._e()]:(_setup.selectedIntegration.settings[_setup.selectedArea])?[_c(_setup.FormHelper,{key:'settings-' + _setup.selectedArea,attrs:{"form":_vm.$store.getters.form('settings.' + _setup.selectedArea) || {},"config":{
        snippetPrefix: 'salesChannelManagementApp',
        title: _vm.$t(
          'salesChannelManagementApp.titles.settings.' + _setup.selectedArea
        ),
        configValues: false,
        customVariables: ['scmaXentralFields'],
        labelStacked: _setup.selectedArea === 'productAssignment',
        labelData: {
          appName: _setup.store.getters.selectedApp.label
        },
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      }},on:{"change":function($event){return _setup.formValueChange($event, 'settings', _setup.selectedArea)},"load-formatter":_setup.loadFormatter},model:{value:(_setup.selectedIntegration.settings[_setup.selectedArea].value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.settings[_setup.selectedArea], "value", $$v)},expression:"selectedIntegration.settings[selectedArea].value"}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }