var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.selectedIntegration?.productSelection?.selection)?_c('div',[_c(_setup.FormHelper,{key:"productSelection-selection",attrs:{"config":{
        snippetPrefix: 'salesChannelManagementApp',
        title: _vm.$t(
          'salesChannelManagementApp.titles.productSelection.selection'
        ),
        configValues: false,
        customVariables: ['scmaXentralFields'],
        labelData: {
          appName: _setup.store.getters.selectedApp.label
        },
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      },"form":_setup.store.getters.form('productSelection.selection') || {}},on:{"change":function($event){_setup.formValueChange($event, 'productSelection', 'selection');
        _setup.formValueChange($event, 'productSelection', 'selectionV2');},"load-formatter":_setup.loadFormatter},model:{value:(_setup.selectedIntegration.productSelection.selection.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.productSelection.selection, "value", $$v)},expression:"selectedIntegration.productSelection.selection.value"}}),_c('v-dialog',{attrs:{"id":"status-modal","content-class":"bg-white","width":"600"},model:{value:(_setup.showArticleImportModal),callback:function ($$v) {_setup.showArticleImportModal=$$v},expression:"showArticleImportModal"}},[_c(_setup.Card,{staticClass:"p-6 text-center"},[_c('div',{},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.journal.articleFound", { stock: _setup.xentralArticleStock }))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.journal.articleListExportInfo"))+" ")]),_c('div',{staticClass:"d-flex mt-8"},[_c('button',{staticClass:"btn btn-outline-secondary w-50",on:{"click":function($event){_setup.showArticleImportModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary w-50 ml-2",on:{"click":_setup.runCFLWorkflow}},[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.journal.createArticleList"))+" ")])])])])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }