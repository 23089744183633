<script setup>
import SettingsForms from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/SettingsForms.vue";
import SkeletonLoading from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/SkeletonLoading.vue";
import { useApp } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useApp";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";

const { isAppInitialized } = useAppStatus();
const { selectedArea } = useArea();
const { selectSettingArea } = useFeatureArea();
const { settingsAreas } = useApp();
</script>

<template>
  <div>
    <SkeletonLoading v-if="!isAppInitialized || !selectedArea" />

    <div v-else>
      <PerfectScrollbar
        v-if="settingsAreas.length > 0"
        class="d-flex tabs align-end mb-4"
      >
        <div
          v-for="(item, i) in settingsAreas"
          :key="i"
          class="tab-title py-3 px-4"
          :class="{
            active: selectedArea === item
          }"
          @click="selectSettingArea(item)"
        >
          <span class="">{{
            $t("salesChannelManagementApp.titles.settings." + item)
          }}</span>
        </div>
      </PerfectScrollbar>

      <SettingsForms />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tabs {
  border-bottom: 1px solid #d0d5dd;

  .tab-title {
    color: $color-connect-text;
    font-weight: bold;
    border: none;
    cursor: pointer;
    white-space: nowrap;

    &.active,
    &:hover {
      box-shadow: 0 -2px 0 $color-connect-primary inset;
      color: $color-connect-primary;
      text-decoration: none;
      font-weight: bold;
    }

    &.active {
      &:hover {
        color: $color-connect-primary;
      }
    }

    &:hover {
      color: $color-connect-text;
    }
  }
}
</style>
