import { computed, ref } from "vue";
import ProcessManager from "@/components/Workflows/processManager";
import ProcessIteration from "@/components/Workflows/processIteration";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useStore } from "@/core/services/store";
import Reporting from "@/components/Workflows/Reporting/reporting";
import { Error } from "@/core/plugins/swal";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useAppSave } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppSave";

const store = useStore();

const CFL_WORKFLOW_ID = computed(
  () => store.getters.environmentVariables.CFLWorkflowId
);
const XENTRAL_WORKFLOW_ID = computed(
  () => store.getters.environmentVariables.xentralWorkflowId
);

const articleImportPending = ref(false);
const lastStoppedIteration = ref({});
const showArticleImportModal = ref(false);
const xentralArticleStock = ref(0);
const isMounted = ref(false);
const timeout = ref();

const { selectedIntegration } = useIntegration();

export const useArticleImport = () => {
  const { setProductSelectionValue, storeArea } = useAppSave();
  const currentArticleImportStatus = computed(() => {
    return (
      lastStoppedIteration.value?.case_identifier ??
      "0 / " + xentralArticleStock.value
    );
  });

  async function runCFLWorkflow() {
    try {
      showArticleImportModal.value = false;
      articleImportPending.value = true;

      const integration = selectedIntegration?.value;

      // Store productsSelected-preset only the first time to prevent duplicate job inside connect
      if (!integration?.productsSelected?.id) {
        const response = await storeArea({
          name: "productsSelected",
          label: "productsSelected",
          type: "json",
          project_id: integration.id,
          value: {
            productsSelected: true
          }
        });

        integration.productsSelected = response?.data;

        await setProductSelectionValue(integration.id);
        await checkForPendingWorkingJobs();
        return;
      }

      await ProcessManager.run(CFL_WORKFLOW_ID.value, {
        dispatchImmediately: true
      });

      await checkForPendingWorkingJobs();
    } catch (error) {
      articleImportPending.value = false;
      Error(error);
    }
  }

  async function checkForPendingWorkingJobs() {
    await getPendingAndWorkingJob();
    // Recursively call the function every 4s until there are none pending/working job
    if (isMounted.value && articleImportPending.value) {
      await getLastStoppedJob();
      timeout.value = setTimeout(checkForPendingWorkingJobs, 4000);
    } else {
      lastStoppedIteration.value = {};
      articleImportPending.value = false;
      xentralArticleStock.value = 0;
    }
  }

  async function getPendingAndWorkingJob() {
    let params = {
      page: 1,
      perPage: 1,
      filter: [
        {
          key: "status",
          op: "in",
          value: ["process.pending", "process.working"]
        }
      ]
    };

    let iteration = await getIteration(params).catch(
      () => (articleImportPending.value = false)
    );
    articleImportPending.value = !!Object.keys(iteration || {}).length;
  }

  async function getLastStoppedJob() {
    let params = {
      page: 1,
      perPage: 1,
      filter: [
        {
          key: "process_id",
          op: "equals",
          value: CFL_WORKFLOW_ID.value
        },
        {
          key: "status",
          op: "equals",
          value: "process.stopped"
        }
      ]
    };

    let iteration = await getIteration(params);
    if (iteration.content_identifier !== "Finished") {
      lastStoppedIteration.value = iteration;
    } else {
      lastStoppedIteration.value = {};
    }
  }

  async function getIteration(params) {
    let iteration = {};
    await Reporting.getIterations(CFL_WORKFLOW_ID.value, params, {}, true)
      .then(response => {
        iteration = response.data?.[0];
      })
      .catch(error => {
        Error(error);
      });
    return iteration;
  }

  async function getXentralArticleStock() {
    addEventToLoadingQueue({ key: "stock" });

    let iterationId = "";
    // Trigger xentral stock workflow
    await ProcessManager.run(XENTRAL_WORKFLOW_ID.value, {
      dispatchImmediately: true
    })
      .then(response => {
        iterationId = response.data?.id;
      })
      .catch(error => {
        Error(error);
      });

    if (!iterationId) return;
    // Get iteration details
    await ProcessIteration.get(iterationId)
      .then(response => {
        let stock = response.data?.return?.data;
        if (isNaN(stock)) return;

        xentralArticleStock.value = stock;
        showArticleImportModal.value = true;
      })
      .catch(error => {
        Error(error);
      });

    removeEventFromLoadingQueue({
      key: "stock"
    });
  }

  return {
    checkForPendingWorkingJobs,
    getXentralArticleStock,
    runCFLWorkflow,
    articleImportPending,
    currentArticleImportStatus,
    isMounted,
    lastStoppedIteration,
    showArticleImportModal,
    xentralArticleStock,
    timeout
  };
};
