<script setup>
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  getCurrentInstance,
  onMounted,
  ref,
  watch
} from "vue";
import { REMOVE_ROUTE_PARAMS_BY_KEY } from "@/core/services/store/route.module";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router/composables";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Pagination from "@/components/Tools/Pagination.vue";
import { getSnippet } from "@/components/Tools/FormHelper/Helper/functions";
import { useApplyOrderBy } from "@/composables/TableHelper/useApplyOrderBy";
import { addEventToLoadingQueue } from "@/composables/useLoadingQueue";
import TableHelperFilter from "@/components/Tools/TableHelper/TableHelperFilter.vue";
import TableHelperActions from "@/components/Tools/TableHelper/TableHelperActions.vue";
import { useStore } from "@/core/services/store";
import { Confirmation } from "@/core/plugins/swal";
import { useParams } from "@/composables/TableHelper/useParams";
import ExportModal from "@/components/Tools/TableHelper/components/ExportModal.vue";
import { nextTick } from "vue";

const props = defineProps({
  actions: {
    type: Array,
    default: () => []
  },
  actionsFixed: {
    type: Boolean,
    default: true
  },
  actionsAlignClass: {
    type: String,
    default: "text-right"
  },
  disableHover: {
    type: Boolean,
    default: false
  },
  disablePageSize: {
    type: Boolean,
    default: false
  },
  disableSearch: {
    type: Boolean,
    default: false
  },
  disableSkeletonLoading: {
    type: Boolean,
    default: false
  },
  enableFilter: {
    type: Boolean,
    default: false
  },
  enableQuickFilter: {
    type: Boolean,
    default: false
  },
  enableMassDelete: {
    type: Boolean,
    default: false
  },
  enablePagination: {
    type: Boolean,
    default: true
  },
  enableReload: {
    type: Boolean,
    default: true
  },
  enableSelect: {
    type: Boolean,
    default: true
  },
  exportable: {
    type: Boolean,
    default: false
  },
  fields: {
    type: Array,
    required: true
  },
  filterable: {
    type: Object,
    default: () => {}
  },
  filterableFieldBlacklist: {
    type: Array,
    default: () => ["project_id"]
  },
  groupKey: {
    type: String,
    default: ""
  },
  height: {
    type: String,
    default: "70vh"
  },
  hideHeader: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    required: true
  },
  loadingKey: {
    type: String,
    required: true
  },
  meta: {
    type: Object,
    default: () => {}
  },
  defaultOrderBy: {
    type: String,
    default: ""
  },
  defaultOrderByDirection: {
    type: String,
    default: "asc"
  },
  noRouteParams: {
    type: Boolean,
    default: false
  },
  paramPrefix: {
    type: [String, Number],
    default: ""
  },
  perPage: {
    type: Number,
    default: 15
  },
  progressBarKey: {
    type: String,
    default: ""
  },
  selectFixed: {
    type: Boolean,
    default: true
  },
  selectMode: {
    type: String,
    default: "multi" // values: multi, single, range
  },
  title: {
    type: String,
    default: null
  },
  useCard: {
    type: Boolean,
    default: true
  },
  buttons: {
    type: Array,
    default: () => []
  },
  selectAllOption: {
    type: Boolean,
    default: true
  }
});

onMounted(() => {
  if (props.perPage) {
    perPage.value = props.perPage;
  }

  applyMeta();
  if (!props.noRouteParams) {
    applyRouteParams();
  }
});

// GENERAL
const route = useRoute();
const router = useRouter();
const store = useStore();
const i18n = getCurrentInstance().proxy.$i18n;
const emit = defineEmits([
  "reload-data",
  "sort-changed",
  "select-all-rows",
  "select-row",
  "search",
  "page-size-change",
  "page-change",
  "execute-filter",
  "reset-filter",
  "delete-selected-rows",
  "export-csv",
  "select-all-rows-in-data",
  "execute-quick-filter",
  "reset-quick-filter"
]);
const prefix = computed(() => props.paramPrefix);
const {
  params,
  page,
  perPage,
  searchTerm,
  order: orderBy,
  filter,
  routeParams,
  paramNamesPrefixed
} = useParams(prefix);
const table = ref(null);
const selectedRows = ref([]);
const allRowsSelected = ref([]);
const selectedAllRowsInData = ref(false);
const { order } = useApplyOrderBy(orderBy);
const totalPages = ref(1);
const totalRecords = ref(0);
const maxDataLength = ref(90);
const modal = ref({
  show: false,
  data: undefined,
  dataString: "",
  title: "",
  editMode: false
});
const showExportModal = ref(false);

// TABLE FORM
const tableForm = ref({
  searchInput: searchTerm.value ?? ""
});
const tableFormDefinition = ref([
  {
    type: "text",
    name: "searchInput",
    placeholder: "searchTerm",
    prependIcon: "fal fa-search",
    disableReturnType: true,
    fieldWrapper: true,
    clearable: true,
    class: "col-12 search",
    disableValidations: true
  }
]);
const tableFormConfig = ref({
  snippetPrefix: "tableHelper",
  showLabels: false,
  fullWidth: true,
  labelStacked: true,
  disableSkeletonLoading: true
});

const formFields = ref({
  activeBadge: () =>
    import("@/components/Tools/TableHelper/FieldTypes/ActiveBadge.vue"),
  badge: () => import("@/components/Tools/TableHelper/FieldTypes/Badge.vue"),
  boolean: () =>
    import("@/components/Tools/TableHelper/FieldTypes/Boolean.vue"),
  datetime: () =>
    import("@/components/Tools/TableHelper/FieldTypes/Datetime.vue"),
  dirtyRecords: () =>
    import("@/components/Tools/TableHelper/FieldTypes/DirtyRecords.vue"),
  progress: () =>
    import("@/components/Tools/TableHelper/FieldTypes/Progress.vue"),
  sourceIcon: () =>
    import("@/components/Tools/TableHelper/FieldTypes/SourceIcon.vue"),
  importState: () =>
    import("@/components/Tools/TableHelper/FieldTypes/ImportState.vue"),
  state: () => import("@/components/Tools/TableHelper/FieldTypes/State.vue"),
  stateMachineOrderNumber: () =>
    import(
      "@/components/Tools/TableHelper/FieldTypes/StateMachineOrderNumber.vue"
    ),
  status: () => import("@/components/Tools/TableHelper/FieldTypes/Status.vue"),
  statusDatetime: () =>
    import("@/components/Tools/TableHelper/FieldTypes/StatusDatetime.vue"),
  time: () => import("@/components/Tools/TableHelper/FieldTypes/Time.vue"),
  user: () => import("@/components/Tools/TableHelper/FieldTypes/User.vue")
});

// FORMHELPER MODAL
const formHelperForm = [
  {
    type: "code",
    name: "dataString",
    lang: "json"
  }
];
const formConfig = {
  snippetPrefix: "table",
  labelStacked: true,
  enableVariables: false
};

const isLoading = computed(() => {
  return store.getters["loadingQueue/showLoadingBar"](
    props.progressBarKey,
    props.groupKey
  );
});

// PARAMS
if (props.defaultOrderBy && !Object.keys(order.value)?.length) {
  orderBy.value = {
    field: props.defaultOrderBy,
    direction: props.defaultOrderByDirection
  };
}

/**
 * Manipulate the table fields, depending on the given props
 */
const tableFields = computed(() => {
  let fields = [...props.fields];

  // Add a select column at the start if enabled
  if (props.enableSelect) {
    fields = [
      {
        key: "select",
        label: "",
        sortable: false,
        class: "select-cell",
        thStyle: { width: "50px" },
        stickyColumn: props.selectFixed
      },
      ...fields
    ];
  }

  // Add an action column at the end if actions exist
  if (props.actions?.length) {
    fields.push({
      key: "actions",
      label: i18n.t("table.actions"),
      sortable: false,
      thStyle: { width: "0" },
      class: "action-cell text-no-wrap " + props.actionsAlignClass,
      stickyColumn: props.actionsFixed
    });
  }

  return fields;
});

const resourceFields = computed(() => {
  return props.meta?.fields ?? [];
});

watch(
  () => props.meta,
  (newMeta, oldMeta) => {
    if (!_.isEqual(newMeta, oldMeta)) {
      applyMeta();
    }
  },
  { deep: true }
);

watch(
  () => tableForm.value?.searchInput,
  async () => {
    handleSearchTermChange();
  }
);

watch(
  () => isLoading.value,
  () => {
    if (!isLoading.value && selectedAllRowsInData.value) {
      nextTick(() => selectAllRows());
    }
  }
);

watch(
  () => selectedAllRowsInData.value,
  () => {
    emit("select-all-rows-in-data", selectedAllRowsInData.value);
    selectAllRows();
  }
);

defineExpose({
  allRowsSelected,
  selectSingleRow,
  selectAllRows,
  selectedRows
});

/**
 * FUNCTIONS
 */
function emitAction(payload) {
  emit(payload.emit, payload.data);
}

function applyMeta() {
  if (!props.meta || !Object.keys(props.meta)?.length) {
    return;
  }
  page.value = props.meta.current_page ?? 0;
  totalPages.value = props.meta.last_page ?? 0;
  totalRecords.value = props.meta.total ?? 0;
}
function applyRouteParams() {
  if (props.noRouteParams) {
    return;
  }

  const { query } = route;
  const [pageParam, perPageParam, searchTermParam] = paramNamesPrefixed.value;

  if (
    !(pageParam in query || perPageParam in query || searchTermParam in query)
  ) {
    setRouteParams();
    return;
  }

  const parseQueryParam = (param, defaultValue = null) =>
    query[param] ? query[param] : defaultValue;

  page.value = parseInt(parseQueryParam(pageParam, page.value));
  perPage.value = parseInt(parseQueryParam(perPageParam, perPage.value));
  searchTerm.value = parseQueryParam(searchTermParam, searchTerm.value);
  tableForm.value.searchInput = searchTerm.value;
}
function setRouteParams() {
  if (!props.noRouteParams) {
    router.replace({ query: routeParams.value }).catch(error => error);
  }
}
function handlePageChange(value) {
  page.value = value;

  prepareReloadData();
  resetSelectedRows();

  emit("page-change", page.value);
}
function handlePageSizeChange(value) {
  page.value = 1;
  perPage.value = value;

  prepareReloadData();
  resetSelectedRows();

  emit("page-size-change", perPage.value);
}
function handleSearchTermChange() {
  if (props.disableSearch) {
    return;
  }
  searchTerm.value = tableForm.value.searchInput;

  prepareReloadData();

  emit("search", searchTerm.value);
}

function resetSelectedRows() {
  selectedRows.value = [];
}

function prepareReloadData(reload = false) {
  emit("prepare-reload");
  addEventToLoadingQueue({
    key: props.loadingKey,
    progressBarKey: props.progressBarKey,
    group: props.loadingKey
  });

  if (reload) {
    setRouteParams();
    emit("reload-data", params.value);
  } else {
    reloadData();
  }
}

const reloadData = _.debounce(() => {
  setRouteParams();
  emit("reload-data", params.value);
}, 350);
function sortChanged(data) {
  store.dispatch("route/" + REMOVE_ROUTE_PARAMS_BY_KEY, {
    prefix: props.paramPrefix ?? "default",
    key: "orderBy"
  });

  orderBy.value = {
    field: data.sortBy,
    direction: data.sortDesc ? "desc" : "asc"
  };

  prepareReloadData();

  emit("sort-changed", data);
}

function executeFilter(data) {
  page.value = 1;
  filter.value = data;

  prepareReloadData();

  emit("execute-filter", filter.value);
}

function resetFilter() {
  filter.value = [];

  prepareReloadData();

  emit("reset-filter");
}

function selectAllRows() {
  const value = allRowsSelected.value[0];
  if (value) {
    table.value.selectAllRows();
  } else {
    selectedAllRowsInData.value = false;
    table.value.clearSelected();
  }

  emit("select-all-rows", value, selectedRows.value);
}

function onSelectRow(data) {
  selectedRows.value = data;

  emit("select-row", data);
}

function selectSingleRow(value, index) {
  if (value) {
    table.value.selectRow(index);
  } else {
    selectedAllRowsInData.value = false;
    table.value.unselectRow(index);
  }
}

function deleteSelectedRows() {
  Confirmation({
    title: i18n.t("tableHelper.confirmDeletion"),
    cancelButtonText: i18n.t("general.cancel"),
    confirmButtonText: i18n.t("general.confirm")
  }).then(result => {
    if (!result.isConfirmed) return;

    emit("delete-selected-rows", selectedRows.value);

    allRowsSelected.value = [];
    table.value.clearSelected();
  });
}

function formatCellData(value) {
  let asString = JSON.stringify(value);
  if (asString?.length <= maxDataLength.value) {
    return asString;
  }
  return asString.substring(0, maxDataLength.value);
}

function showFullValue(field, value, editMode = false) {
  modal.value = {
    show: true,
    title: getSnippet(field, "table"),
    data: value,
    dataString: JSON.stringify(value, null, "\t"),
    editMode: editMode
  };
}

function closeModal() {
  modal.value = {
    show: false,
    title: "",
    data: undefined,
    dataString: undefined,
    editMode: false
  };
}
</script>

<template>
  <div class="table-helper-wrapper">
    <div :class="[useCard ? 'card card-custom' : '']">
      <div class="column px-0" :class="[useCard ? 'card-body' : '']">
        <div v-if="!props.hideHeader" class="table-helper-top px-5">
          <div v-if="title" class="row">
            <div class="col-12">
              <span class="h5 font-weight-bolder text-dark mb-3">
                {{ title }}
              </span>
            </div>
          </div>
          <div class="row align-items-center mb-3 mt-2 justify-content-between">
            <div class="col-auto align-items-center table-helper-toolbar">
              <slot name="beforeToolbar" />
              <FormHelper
                v-if="!disableSearch"
                key="tableForm"
                v-model="tableForm"
                class="table-helper-search"
                :form="tableFormDefinition"
                :config="tableFormConfig"
              />

              <TableHelperFilter
                v-if="enableFilter"
                :filterable="filterable"
                :field-blacklist="filterableFieldBlacklist"
                :param-prefix="paramPrefix"
                @execute-filter="executeFilter"
                @reset-filter="resetFilter"
              ></TableHelperFilter>

              <slot name="afterToolbar" />
            </div>

            <div
              class="col-auto d-flex align-items-center justify-content-end table-helper-button-bar"
            >
              <template v-if="selectedRows?.length">
                <span
                  v-for="button in buttons"
                  :key="button.key"
                  v-b-popover.hover.bottom="button.tooltip"
                  class="btn btn-icon btn-hover-light-primary"
                  @click="emit(button.emit)"
                >
                  <v-badge
                    :content="selectedAllRowsInData ? '' : selectedRows.length"
                  >
                    <template v-if="selectedAllRowsInData" #badge>
                      <i class="fal fa-list-check badge-icon fa-sm" />
                    </template>
                    <i :class="button.icon" />
                  </v-badge>
                </span>

                <span
                  v-if="enableMassDelete"
                  v-b-popover.hover.bottom="
                    $t('tableHelper.deleteSelectedRows')
                  "
                  class="btn btn-icon btn-sm btn-outline-danger"
                  @click="deleteSelectedRows()"
                >
                  <v-badge :content="selectedRows.length" color="error">
                    <i class="fal fa-trash"></i>
                  </v-badge>
                </span>
              </template>

              <slot name="beforeHeaderButtons" />

              <span
                v-if="exportable && resourceFields?.length"
                v-b-popover.top.hover="$t('tableHelper.export.title')"
                class="btn btn-icon btn-hover-light-primary btn-csv-export"
                @click="showExportModal = true"
              >
                <i class="fal fa-file-csv" />
              </span>

              <span
                v-if="props.enableReload"
                v-b-popover.top.hover="$t('general.reload')"
                class="btn btn-icon btn-sm btn-hover-light-primary btn-reload"
                @click="prepareReloadData"
              >
                <i class="fal fa-sync-alt"></i>
              </span>

              <slot name="afterHeaderButtons" />
            </div>
          </div>
        </div>

        <!--    Skeleton loading    -->
        <b-skeleton-table
          v-show="isLoading && !disableSkeletonLoading"
          :rows="5"
          :columns="fields?.length"
        />

        <b-table
          v-show="!isLoading || disableSkeletonLoading"
          ref="table"
          class="table-helper"
          :class="{ 'd-none': isLoading && !disableSkeletonLoading }"
          :table-class="{ 'table-helper-hover': !disableHover }"
          :fields="tableFields"
          :items="items"
          :sticky-header="height"
          no-border-collapse
          no-local-sorting
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          selected-variant="primary"
          :selectable="enableSelect"
          :show-empty="items?.length <= 0"
          :empty-text="$t('table.noRecords')"
          :select-mode="selectMode"
          :sort-by="defaultOrderBy ?? undefined"
          :sort-direction="defaultOrderByDirection"
          @sort-changed="sortChanged"
          @row-selected="onSelectRow"
        >
          <template #head(select)>
            <b-form-checkbox-group
              v-if="selectMode !== 'single'"
              size="lg"
              class="pl-3"
            >
              <b-form-checkbox
                v-model="allRowsSelected"
                @change="selectAllRows"
              ></b-form-checkbox>
            </b-form-checkbox-group>
          </template>

          <template #cell(select)="data">
            <b-form-checkbox-group size="lg" class="pl-3">
              <b-form-checkbox
                :checked="data.rowSelected"
                @change="val => selectSingleRow(val, data.index)"
              ></b-form-checkbox>
            </b-form-checkbox-group>
          </template>

          <template #cell(actions)="data">
            <TableHelperActions
              :data="data"
              :actions="actions"
              @action="emitAction"
            ></TableHelperActions>
          </template>

          <template
            v-if="
              props.selectAllOption && allRowsSelected[0] && totalRecords > 0
            "
            #top-row="{ columns }"
          >
            <td :colspan="columns">
              <div v-if="selectedAllRowsInData">
                {{
                  $t("tableHelper.rows.allSelected", { count: totalRecords })
                }}
              </div>
              <div v-else>
                {{
                  $t("tableHelper.rows.pageSelected", {
                    count: selectedRows.length
                  })
                }}
                <span class="select-all" @click="selectedAllRowsInData = true">
                  {{ $t("tableHelper.rows.selectAll") }}
                </span>
              </div>
            </td>
          </template>

          <template #cell()="data">
            <component
              :is="formFields[data.field.type]"
              v-if="data.field.type"
              :data="data"
              :field="data.field"
            />
            <i
              v-if="data.field.iconConfig && data.field.iconConfig[data.value]"
              class="icon-lg"
              :class="data.field.iconConfig[data.value]"
            ></i>
            <span
              v-else-if="
                JSON.stringify(data.value)?.length > maxDataLength &&
                !data.field.type
              "
              class="table-helper-read-more"
              @click="showFullValue(data.field.key, data.value)"
            >
              {{ formatCellData(data.value) }}
              <span
                class="label label-inline label-light-secondary px-1 cursor-pointer"
                >[...]
              </span>
            </span>
            <span v-else-if="!data.field.type">
              {{ data.value }}
            </span>
          </template>
        </b-table>

        <ExportModal
          v-model="showExportModal"
          :fields="resourceFields"
          :props="props"
          @export-csv="emit('export-csv', $event)"
        />

        <b-modal
          id="datasets-preview-modal"
          v-model="modal.show"
          size="xl"
          :title="modal.title.toString()"
          centered
          scrollable
          @hidden="closeModal"
        >
          <FormHelper
            v-if="typeof modal.data === 'object'"
            ref="form"
            v-model="modal"
            :form="formHelperForm"
            :config="formConfig"
          />
          <span v-else>{{ modal.data }}</span>
        </b-modal>

        <div v-if="enablePagination" class="table-helper-bottom px-5 pb-5">
          <Pagination
            :key="paramPrefix"
            :total-records="totalRecords"
            :total-pages="totalPages"
            :per-page="perPage"
            :disable-page-size="disablePageSize"
            :from="meta.from ?? 0"
            :to="meta.to ?? 0"
            :param-prefix="paramPrefix"
            @change-page="handlePageChange"
            @change-page-size="handlePageSizeChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-helper {
  border-bottom: 1px solid #ebedf3;

  :deep(table) {
    &.table {
      td,
      th {
        &.b-table-sticky-column {
          &.action-cell {
            right: 0;
          }
        }
      }

      th {
        font-weight: 500;

        &.select-cell {
          min-width: 0;
        }
      }

      td {
        &.select-cell {
          border-right: 1px solid #ebedf3;
          min-width: 0;
        }

        &.action-cell {
          border-left: 1px solid #ebedf3;
        }
      }

      > thead {
        > tr {
          > th {
            background-color: lighten($color-connect-background, 2.5%);
            border-bottom-color: #98a2b3;
            border-bottom-width: 1px;
            border-top: none;
          }
        }
      }

      > tbody {
        > tr {
          &:first-of-type {
            > td {
              border-top: none;
            }
          }

          &.b-table-row-selected {
            &.table-primary {
              &:not(:hover) {
                > td {
                  &.b-table-sticky-column {
                    background-color: $color-connect-primary-medium;
                  }

                  &.select-cell,
                  &.action-cell {
                    border-color: #aaaef6;
                  }
                }
              }
            }
          }

          &.b-table-top-row {
            td {
              cursor: default;
              text-align: center;
            }

            &:hover {
              td {
                background-color: $color-connect-white !important;
              }
            }
          }
        }
      }

      .btn {
        &-icon {
          i {
            color: $color-connect-text;
          }

          &:hover {
            i {
              color: $color-connect-primary;
            }
          }
        }
      }

      &.table-helper-hover {
        > tbody {
          > tr {
            &:hover {
              background-color: $color-connect-primary-light;

              td {
                background-color: $color-connect-primary-light;
                border-color: #f1f3ff;
              }
            }

            &.b-table-row-selected {
              &.table-primary {
                &:hover {
                  > td {
                    &.b-table-sticky-column {
                      background-color: $color-connect-primary-light;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &-wrapper {
    .form-helper {
      :deep(.form-control) {
        background-color: $color-connect-background;

        &[contenteditable] {
          &::before {
            color: $color-connect-text;
          }
        }
      }

      :deep(.page-size) {
        .form-control {
          margin-top: 0;
        }
      }
    }

    .b-pagination {
      :deep(.page-item) {
        &.active {
          .page-link {
            cursor: default;
            height: 100%;
          }
          i {
            font-size: 1rem;
          }
          input {
            &[type="number"] {
              -moz-appearance: textfield;
              width: var(--input-width);
              min-width: 10px;
              outline: none;
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                display: none;
              }
              padding: 0 4px;
              margin: -4px 0;
              text-align: center;
              height: 100%;
            }
          }
        }
      }
      &.is-page-edit {
        .page-item {
          &.active {
            .page-link {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  &-toolbar {
    display: flex;
    gap: 8px;

    .form-helper {
      :deep(.page-size) {
        .v-select {
          input {
            min-width: 25px;
          }
        }
      }
    }
  }

  &-button-bar {
    gap: 10px;
  }

  &-search {
    width: 200px;
  }

  &-read-more {
    color: $color-connect-primary;
    text-decoration: underline;
    font-weight: 500;
  }
}

.btn-icon {
  i {
    color: $color-connect-text;

    &.badge-icon {
      color: $color-connect-white !important;
    }
  }
}

.b-skeleton-text {
  height: 1.5rem;
  margin: 0.375rem 0;
}

.select-all {
  color: $color-connect-primary;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

:deep(.CodeMirror) {
  height: 70vh;
}
</style>
