<script setup>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import { useStore } from "@/core/services/store";
import { useLoadFormatters } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useLoadFormatters";
import { onMounted, onUnmounted } from "vue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useArticleImport } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArticleImport";
import Card from "@/components/Tools/Card/Card.vue";

const store = useStore();
const { selectedArea } = useArea();
const { selectedFeature } = useFeatures();
const { selectedIntegration, formValueChange } = useIntegration();
const { loadFormatter } = useLoadFormatters();

const { runCFLWorkflow, showArticleImportModal, xentralArticleStock } =
  useArticleImport();

onMounted(async () => {
  selectedFeature.value = "productSelection";
  selectedArea.value = "selection";
});

onUnmounted(() => (showArticleImportModal.value = false));
</script>

<template>
  <div>
    <div v-if="selectedIntegration?.productSelection?.selection">
      <FormHelper
        key="productSelection-selection"
        v-model="selectedIntegration.productSelection.selection.value"
        :config="{
          snippetPrefix: 'salesChannelManagementApp',
          title: $t(
            'salesChannelManagementApp.titles.productSelection.selection'
          ),
          configValues: false,
          customVariables: ['scmaXentralFields'],
          labelData: {
            appName: store.getters.selectedApp.label
          },
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp'
        }"
        :form="store.getters.form('productSelection.selection') || {}"
        @change="
          formValueChange($event, 'productSelection', 'selection');
          formValueChange($event, 'productSelection', 'selectionV2');
        "
        @load-formatter="loadFormatter"
      />

      <v-dialog
        id="status-modal"
        v-model="showArticleImportModal"
        content-class="bg-white"
        width="600"
      >
        <Card class="p-6 text-center">
          <div class="">
            <div class="font-weight-bold mb-2">
              {{
                $t("salesChannelManagementApp.journal.articleFound", {
                  stock: xentralArticleStock
                })
              }}
            </div>
            <div>
              {{
                $t("salesChannelManagementApp.journal.articleListExportInfo")
              }}
            </div>
            <div class="d-flex mt-8">
              <button
                class="btn btn-outline-secondary w-50"
                @click="showArticleImportModal = false"
              >
                {{ $t("general.cancel") }}
              </button>
              <button class="btn btn-primary w-50 ml-2" @click="runCFLWorkflow">
                {{ $t("salesChannelManagementApp.journal.createArticleList") }}
              </button>
            </div>
          </div>
        </Card>
      </v-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
