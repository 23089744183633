<script setup>
import { useMigrationIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigrationIntegration";
import { computed, ref } from "vue";
import Modal from "@/components/Tools/Modal.vue";
import Loader from "@/components/Tools/Loader.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useStore } from "@/core/services/store";
import {
  integrationFormAllIntegrations,
  integrationForms
} from "@/components/ExternalApps/SalesChannelManagementApp/utility/integrationForms";

const {
  show,
  isLocked,
  channels,
  selectedChannel,
  selectedChannelId,
  startMigration
} = useMigrationIntegration();
const step = ref(1);
const store = useStore();

const config = {
  snippetPrefix: "salesChannelManagementApp.migration.form",
  labelColClass: "col-5",
  fieldColClass: "col-7",
  fieldColClassXl: "col-7",
  disableSkeletonLoading: true
};
const configSelectForm = {
  disableSkeletonLoading: true,
  labelStacked: true,
  disableValidations: true
};
const migrationSelectField = computed(() => [
  {
    name: "channelId",
    type: "select",
    options: channels.value?.map(c => {
      return {
        label: c.name,
        value: c.id
      };
    }),
    default: channels.value?.[0].id
  }
]);
const appName = computed(() => store.getters.selectedApp.name);
const forms = computed(() => integrationForms?.[appName.value] ?? []);
const model = ref({});
const formHelper = ref();

function validate() {
  if (!formHelper.value) return (step.value = 3);
  let counter = 0;
  // Validate each form
  for (const form of formHelper.value) {
    if (form.validate() !== true) {
      counter++;
    }
  }
  // If valid go to next step
  if (counter === 0) step.value = 3;
}
</script>

<template>
  <Modal
    :show-modal="show"
    :show-footer="false"
    :disable-close="isLocked"
    type="large"
    @toggle-modal="show = $event"
  >
    <template #content>
      <v-stepper v-model="step" flat>
        <v-stepper-items>
          <v-stepper-content step="1">
            <div>
              <div>
                <div class="h5">
                  {{
                    $t("salesChannelManagementApp.migration.selectIntegration")
                  }}
                </div>
                <div
                  v-if="
                    !!store.getters['loadingQueue/showLoadingBar'](
                      'loadChannels'
                    )
                  "
                  class="loader"
                >
                  <Loader />
                </div>
                <div v-else class="d-flex flex-wrap mt-4">
                  <div v-if="channels.length === 0">
                    {{
                      $t("salesChannelManagementApp.migration.noChannelsFound")
                    }}
                  </div>
                  <div v-else>
                    <FormHelper
                      v-model="selectedChannelId"
                      :form="migrationSelectField"
                      :config="configSelectForm"
                    >
                    </FormHelper>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-6">
                <button
                  :disabled="isLocked"
                  class="btn btn-back"
                  size="sm"
                  @click="show = false"
                >
                  {{ $t("general.cancel") }}
                </button>
                <button
                  :disabled="isLocked || !selectedChannel"
                  class="btn btn-primary"
                  size="sm"
                  @click="step = 2"
                >
                  {{ $t("general.next") }}
                </button>
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <h5 class="mb-4">
              {{ $t("salesChannelManagementApp.migration.formularTitle") }}
            </h5>
            <div v-for="(form, i) in forms" :key="i">
              <FormHelper
                ref="formHelper"
                v-model="model[i]"
                :config="config"
                :form="form"
              ></FormHelper>
            </div>
            <FormHelper
              v-model="model"
              :config="config"
              :form="integrationFormAllIntegrations"
            ></FormHelper>
            <div class="d-flex justify-content-between mt-6">
              <button
                :disabled="isLocked"
                class="btn btn-back"
                size="sm"
                @click="show = false"
              >
                {{ $t("general.cancel") }}
              </button>
              <button
                :disabled="isLocked"
                class="btn btn-primary"
                size="sm"
                @click="validate"
              >
                {{ $t("general.next") }}
              </button>
            </div>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div
              v-html="
                $t(
                  'salesChannelManagementApp.migration.integrationSetupConfirmation'
                )
              "
            ></div>
            <div
              class="mt-4"
              v-html="
                $t(
                  'salesChannelManagementApp.migration.' +
                    store.getters.selectedApp.name +
                    'IntegrationLink'
                )
              "
            ></div>
            <div class="d-flex justify-content-between mt-6">
              <button
                :disabled="isLocked"
                class="btn btn-back"
                size="sm"
                @click="show = false"
              >
                {{ $t("general.cancel") }}
              </button>
              <button
                :disabled="isLocked"
                class="btn btn-primary"
                size="sm"
                @click="startMigration(model)"
              >
                {{
                  $t(
                    "salesChannelManagementApp.migration.migrateExistingIntegration"
                  )
                }}
              </button>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.loader {
  height: 100px;
}
.btn-back {
  color: #344054;
  &:disabled {
    opacity: 0.6;
    filter: grayscale(1);
  }
}
</style>
