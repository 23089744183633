import { computed, ref } from "vue";
import { Error } from "@/core/plugins/swal";
import ProcessManager from "@/components/Workflows/processManager";
import ProcessIteration from "@/components/Workflows/processIteration";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export const useWorkflowStatus = workflowId => {
  const workflowPending = ref(false);
  const lastStoppedIteration = ref({});
  const isMounted = ref(false);
  const timeout = ref();

  const currentWorkflowStatus = computed(() => {
    return lastStoppedIteration.value?.case_identifier ?? "0 / 0";
  });

  const { selectedIntegration } = useIntegration();

  async function runWorkflow(params = {}) {
    addEventToLoadingQueue({
      key: "workflowStatus"
    });
    try {
      workflowPending.value = true;
      await ProcessManager.run(workflowId, {
        params: params
      });
      await checkForPendingWorkingJobs();
    } catch (error) {
      workflowPending.value = false;
      Error(error);
    }
    removeEventFromLoadingQueue({
      key: "workflowStatus"
    });
  }

  async function checkForPendingWorkingJobs() {
    await getPendingAndWorkingJob();
    await getLastStoppedJob();
    // Recursively call the function every 4s until there are none pending/working job
    if (isMounted.value && workflowPending.value) {
      timeout.value = setTimeout(checkForPendingWorkingJobs, 4000);
    } else {
      if (lastStoppedIteration.value.return?.initialImportFile) {
        selectedIntegration.value.product.initialImportFile.value =
          lastStoppedIteration.value.return?.initialImportFile;
      }
      lastStoppedIteration.value = {};
      workflowPending.value = false;
    }
  }

  async function getPendingAndWorkingJob() {
    let params = {
      page: 1,
      perPage: 1,
      filter: [
        {
          key: "status",
          op: "in",
          value: ["process.pending", "process.working"]
        },
        {
          key: "process_id",
          op: "equals",
          value: workflowId
        }
      ]
    };

    if (!workflowPending.value) {
      addEventToLoadingQueue({
        key: "workflowStatus"
      });
    }

    let iteration = await getIteration(params).catch(
      () => (workflowPending.value = false)
    );
    workflowPending.value = !!Object.keys(iteration || {}).length;

    removeEventFromLoadingQueue({
      key: "workflowStatus"
    });
  }

  async function getLastStoppedJob() {
    let params = {
      page: 1,
      perPage: 1,
      filter: [
        {
          key: "process_id",
          op: "equals",
          value: workflowId
        },
        {
          key: "status",
          op: "equals",
          value: "process.stopped"
        }
      ]
    };

    lastStoppedIteration.value = await getIteration(params);
  }

  async function getIteration(params) {
    let iteration = {};
    await ProcessIteration.getAll(params)
      .then(response => {
        iteration = response.data?.[0];
      })
      .catch(error => {
        Error(error);
      });
    return iteration;
  }

  return {
    checkForPendingWorkingJobs,
    runWorkflow,
    workflowPending,
    currentWorkflowStatus,
    isMounted,
    lastStoppedIteration,
    timeout
  };
};
